import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'
import { stringify } from 'qs'

const endpoint = 'categories'
const Actions = crudActions(endpoint)

const getActiveCategoryList = ({commit}, queryParams) => new Promise((resolve, reject) => {
    queryParams = {...queryParams, ...{is_active: 1}}
    axios.get(`/${endpoint}`, {
        params: queryParams,
        paramsSerializer: params => stringify(params),
    })
        .then(response => {
            commit('LIST', response.data.data.data)
            resolve(response)
        })
        .catch(error => {
            console.log(error)
            reject(error)
        })
})
const getInActiveCategoryList = ({commit}, queryParams) => new Promise((resolve, reject) => {
    queryParams = {...queryParams, ...{is_active: 0}}
    axios.get(`/${endpoint}`, {
        params: queryParams,
        paramsSerializer: params => stringify(params),
    })
        .then(response => {
            commit('LIST', response.data.data.data)
            resolve(response)
        })
        .catch(error => {
            console.log(error)
            reject(error)
        })
})

const update = (ctx, data) => axios.put(`/${endpoint}/${data.id}`, data)

const setActive = (ctx, data) => new Promise((resolve, reject) => {
    data.is_active = 'true';
    axios.put(`/${endpoint}/${data.id}`, data)
        .then(response => {
            resolve(response)
        }).catch(error => {
        console.log(error)
        reject(error)
    })
})
const setInActive = (ctx, data) => new Promise((resolve, reject) => {
    data.is_active = 'false';
    axios.put(`/${endpoint}/${data.id}`, data)
        .then(response => {
            resolve(response)
        }).catch(error => {
        console.log(error)
        reject(error)
    })
})
export default {
  ...Actions,
    getActiveCategoryList,
    getInActiveCategoryList,
    update,
    setActive,
    setInActive,
  getInThisCategoryProducts: ({ commit }, queryParams) => new Promise((resolve, reject) => {
    axios.get('products', { params: queryParams, paramsSerializer: params => stringify(params) })
      .then(response => {
        commit('SET_IN_THIS_CATEGORY_PRODUCTS_LIST', response.data.data.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
