export default {
  GET(state, data) {
    state.category = { ...data }
  },
  LIST(state, data) {
    state.categories = data
  },
  SET_FORM(state, data) {
    state.categoryForm = { ...data }
  },
  SET_IN_THIS_CATEGORY_PRODUCTS_LIST(state, data) {
    state.inThisCategoryProducts = [data]
  },
}
